import * as React from "react";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { setVegItem } from "../../utils/ReduxStore/VegSlice";
import { setNonVegItem } from "../../utils/ReduxStore/nonVegslice";

const Filterbtn = () => {
  const [isChecked, setIsChecked] = React.useState(false);
  const [btnvalue, setbtnvalue] = React.useState("All");
  const data = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const vegItems = useSelector((state) => state.veg.vegItems);
  const nonvegItems = useSelector((state) => state.nonveg.nonvegItems);

  React.useEffect(() => {
    if (nonvegItems) {
      dispatch(setVegItem(false));
      setIsChecked(!nonvegItems);
      setbtnvalue("All");
    }
  }, [nonvegItems, dispatch]);

  const handleChange = () => {
    setIsChecked((prev) => !prev);

    if (!isChecked) {
      dispatch(setNonVegItem(false));
      dispatch(setVegItem(true));
    } else {
      dispatch(setVegItem(false));
    }
  };
  React.useEffect(() => {
    if (vegItems) {
      setIsChecked(true);
    }
  }, [vegItems]);

  return (
    <div className="flex justify-center items-center m-1 border rounded-2xl w-20 h-9">
      <Switch
        color="default"
        onChange={handleChange}
        checked={isChecked}
        sx={{
          "& .MuiSwitch-thumb": {
            width: "21px",
            height: "21px",
            borderRadius: "4px",
            boxShadow: "none",
            backgroundImage: isChecked
              ? // ?
                "url(Images/Veg.png)"
              : "url(Images/Veg.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          },
          "& .MuiSwitch-track": {
            backgroundColor: isChecked ? "#4caf50" : "gray",
            width: "40px",
            height: "12px",
          },

          "&:focus": {
            "--tw-ring-color": "#4caf50",
          },
        }}
      />
      <label htmlFor="VEG"></label>
    </div>
  );
};

export default Filterbtn;
