import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import configData from "../../utils/config.json";
import { v4 as uuidv4 } from "uuid";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    cart_id: "",
  },
  reducers: {
    getCartItems: (state, action) => void (state.items = action.payload),
    addToCart: (state, action) => void (state.cart_id = action.payload.cart_id),
    setCartItems: (state, action) => void (state.items = action.payload),
    setOrderTypeChecked:(state,action)=>{
      if (state.items[action.payload].isChecked === true) {
        state.items[action.payload].isChecked = false;
      } else {
        state.items[action.payload].isChecked = true;
      }
    },
    pushItems: (state, action) => {
      ;
      const cartitemFilter = state.items.findIndex((item) => {
        if (item.itemId === action.payload.itemId) {
          return true;
        } else {
          return false;
        }
      });

      const item = action.payload;
      if (cartitemFilter === -1) {
        //  state.items.push({ itemId: action.payload, quantity: 1, price: 0 });
        state.items.push({ ...item });
      } else {
        state.items[cartitemFilter].quantity += 1;
        const newData = [...state.items];

        state.items = newData;
        console.log("newData", newData);
      }
      console.log(state.items);
    },
    removeItems: (state, action) => {
      debugger
      const cartitemFilter = state.items.findIndex((item) => {
        if (item.itemId === action.payload.itemId) {
          return true;
        } else {
          return false;
        }
      });
      if (state.items[cartitemFilter].quantity === 1) {
        const newData = [...state.items];
        newData.splice(cartitemFilter, 1);
        state.items = newData;
      } else {
        const newData = [...state.items];
        newData[cartitemFilter].quantity -= 1;
        state.items = newData;
      }
    },
    clearCart: (state, action) => {
      state.items.length = 0;
    },
    deleteItem:(state,action)=>{
      debugger
      const cartitemFilter = state.items.findIndex((item) => {
        if (item.itemId === action.payload.itemId) {
          return true;
        } else {
          return false;
        }
      });
       const newData = [...state.items];
       newData.splice(cartitemFilter, 1);
       state.items = newData;


    },
    clearCartId: (state, action) => {
      state.cart_id = "";
    },
  },
});

export const addCartItems = createAsyncThunk(
  "cartItems/post",

  async (args, { dispatch, getState }) => {
    const table_no = localStorage.getItem("tabel_no");
    const guest_id = localStorage.getItem("guest_id");
    const id = localStorage.getItem("org_unique_id");
    const item = args;
    const state = getState();
    const cartId = state.cart.cart_id;
    dispatch(pushItems(item));

    try {
      const requestData = {
        cart_id: cartId ? cartId : uuidv4(),
        guest_id: guest_id,
        table_number: table_no,
        itemId: item.id,
        quantity: 1,
        price: item.price,
        user_unique_id: id,
        order_type: "Dine-in",
      };
      localStorage.setItem("cart_Id", cartId);
      const response = await axios.post(
        `${configData.SERVER_URL}shopping_cart/`,
        requestData
      );
      dispatch(addToCart(response.data));
      dispatch(getCartItemsDetails(response.data.cart_id));
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCartItemsDetails = createAsyncThunk(
  "cartItems/post",
  async (cartId, { dispatch }) => {
    try {
      const response = await axios.get(
        `${configData.SERVER_URL}shopping_cart/${cartId}`
      );

      dispatch(getCartItems(response.data));
    } catch (error) {
      console.log(error);
    }
  }
);
// export const deleteItem = createAsyncThunk(
//   "cartItems/delete",

//   async (props, { getState, dispatch }) => {
//     const state = getState();
//     debugger;

//     try {
//       await axios.delete(
//         `${configData.SERVER_URL}shopping_cart/${props.cartid}/cart_item`,
//         {
//           params: { cart_id: props.cartid, cart_item_id: props.dishId },
//         }
//       );
//       if (state.cart.items.length !== 0) {
//         dispatch(getCartItemsDetails(props.cartid));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );
export const updateCartItemsDetails = createAsyncThunk(
  "cartItems/put",
  async (props, { getState, dispatch }) => {
   
    const state = getState();

    const quantityItem = state.cart.items.filter((item) => {
      if (item.itemId === props.item.itemId) {
        return true;
      } else {
        return false;
      }
    });

    if (quantityItem[0].quantity === 1 && props.action !== "ADD") {
       dispatch(removeItems(props.item));
      // dispatch(
      //   deleteItem( props.item)
      // );
      return;
    }
    props.action === "ADD"
      ? dispatch(pushItems(props.item))
      : dispatch(removeItems(props.item));
    try {
      await axios.put(
        `${configData.SERVER_URL}shopping_cart/${state.cart.cart_id}/cart_item`,
        {
          quantity:
            props.action === "ADD"
              ? (props.quantity += 1)
              : (props.quantity -= 1),
        },
        {
          params: {
            cart_id: state.cart.cart_id,
            cart_item_id: props.itemId,
          },
        }
      );
      // console.log(response);
      dispatch(getCartItemsDetails(state.cart.cart_id));
    } catch (error) {
      console.log(error);
    }
  }
);

export const {
  getCartItems,
  setOrderTypeChecked,
  setCartItems,
  addToCart,
  pushItems,
   deleteItem,
  removeItems,
  clearCart,
  clearCartId,
} = cartSlice.actions;

export default cartSlice.reducer;
