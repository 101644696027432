import React, { useState, useEffect } from "react";
import DATA from "../../utils/restaurentdata.json";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

const imgData = [
  "https://yenu.menu/demo/images/gallery/1.webp",
  "https://yenu.menu/demo/images/gallery/5.webp",
  "https://yenu.menu/demo/images/gallery/4.webp",
  "https://yenu.menu/demo/images/gallery/2.webp",
];

const Aboutus = () => {
  const [index, setIndex] = useState(0);
  setTimeout(() => {
    var slider = new KeenSlider("#my-slider", {
      mode: "snap",
      rtl: false,
      slides: {
        perView: "auto",
        spacing: 20,
      },
    });
    slider.update({
      mode: "snap",
      rtl: false,
      slides: {
        perView: "auto",
        spacing: 20,
      },
    });
  }, 0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % imgData.length);
    }, 1000); // Adjust the interval as needed (in milliseconds)

    return () => clearInterval(interval);
  }, []);

  const handlePrev = () => {
    setIndex((prevIndex) =>
      prevIndex <= 0 ? imgData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex >= imgData.length - 1 ? 0 : prevIndex + 1
    );
  };
  return (
    <div className="flex  items-center flex-col  justify-center w-full  mt-4 pb-40">
      <div className="relative flex justify-center  items-center ">
        <img
          src={imgData[index]}
          alt={`Slide ${index + 1}`}
          className="w-full "
        />
        <div className=" p-3 absolute mt-8 transform -translate-y-1/2 flex justify-between items-center w-full">
          <button
            onClick={handlePrev}
            className="bg-slate-100 flex justify-center items-center rounded-full w-5 h-5"
          >
            <FaAngleLeft style={{ margin: "2px" }} />
          </button>
          <button
            onClick={handleNext}
            className="bg-white  rounded-full w-5 h-5"
          >
            <FaAngleRight style={{ margin: "2px" }} />
          </button>
        </div>
      </div>
      <div className="flex justify-start flex-col lg:w-12/12 w-11/12">
        <div className="border-b-2">
          <h2 className="font-bold text-l py-2 px-4">Timings:</h2>
          <h2 className=" text-normal py-2 px-5 ">
            Opening Time : {DATA.hours.open}
          </h2>
          <h2 className="font-normal text-l py-2 px-5 mb-3">
            Closing Time : {DATA.hours.close}
          </h2>
        </div>
        <div className="border-b-2">
          <h2 className="font-bold text-l py-2 px-4">Address:</h2>
          <h2 className=" text-normal py-2 px-5 my-3">
            {DATA.address.buildingName} {DATA.address.landmark}
            {DATA.address.area}
            {DATA.address.city}
          </h2>
        </div>
        <div className="border-b-2">
          <h2 className="font-bold text-l py-2 px-4">Phone:</h2>
          <h2 className=" text-normal py-2 px-5 my-3">{DATA.phone.general}</h2>
        </div>
        <div className="border-b-2">
          <h2 className="font-bold text-l py-2 px-4">WhatsApp:</h2>
          <h2 className=" text-normal py-2 px-5 my-3">{DATA.phone.whatsapp}</h2>
        </div>
        <div
          className="keen-slider border-b-2 overflow-y-auto flex relative touch-pan-y py-4 scrollbar-none"
          id="my-slider"
          style={{ height: "80px" }}
        >
          <button
            style={{
              overflow: "visible",
              textAlign: "center",
              borderRadius: "5px",
              transform: "translate3d(0px, 0px, 0px)",
              minHeight: "20px",
            }}
            className="keen-slider__slide  h-10 px-6 py-1 bg-blue-600 rounded text-white"
          >
            <span
              style={{
                fontSize: "15px",
                color: "white",
                display: "inline-block",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
            >
              Facebook
            </span>
          </button>
          <button
            style={{
              overflow: "visible",
              textAlign: "center",
              borderRadius: "5px",
              transform: "translate3d(0px, 0px, 0px)",
              minHeight: "20px",
            }}
            className="keen-slider__slide h-10 px-6 py-2 bg-pink-600 rounded text-white"
          >
            <span
              style={{
                fontSize: "15px",
                color: "white",
                display: "inline-block",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
            >
              Instagram
            </span>
          </button>
          <button
            style={{
              overflow: "visible",
              textAlign: "center",
              borderRadius: "5px",
              transform: "translate3d(0px, 0px, 0px)",
              minHeight: "20px",
            }}
            className="keen-slider__slide h-10 px-6 py-2 bg-red-500 rounded text-white"
          >
            <span
              style={{
                fontSize: "15px",
                color: "white",
                display: "inline-block",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
            >
              YouTube
            </span>
          </button>
          <button
            style={{
              overflow: "visible",
              textAlign: "center",
              borderRadius: "5px",
              transform: "translate3d(0px, 0px, 0px)",
              minHeight: "20px",
            }}
            className="keen-slider__slide h-10 px-6 py-2 bg-blue-500 rounded text-white"
          >
            <span
              style={{
                fontSize: "15px",
                color: "white",
                display: "inline-block",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
            >
              Twitter
            </span>
          </button>
          <button
            style={{
              overflow: "visible",
              textAlign: "center",
              borderRadius: "5px",
              transform: "translate3d(0px, 0px, 0px)",
              minHeight: "20px",
            }}
            className="keen-slider__slide h-10 px-6 py-2 bg-blue-900 rounded text-white"
          >
            <span
              style={{
                fontSize: "15px",
                color: "white",
                display: "inline-block",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
            >
              Linkdin
            </span>
          </button>
        </div>

        <div className="border-b-2">
          <h2 className="font-bold text-l py-2 px-4">Services:</h2>
          <h2 className=" text-normal py-2 px-5  my-3">
            {DATA.services[0] +
              "," +
              " " +
              DATA.services[1] +
              "," +
              " " +
              DATA.services[2]}
          </h2>
        </div>
        <div className="border-b-2 ">
          <h2 className="font-bold text-l py-2 px-4">Cuisines:</h2>
          <h2 className=" text-normal py-2 px-5 my-3">
            {DATA.cuisines[0] + "," + " " + DATA.cuisines[1]}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
