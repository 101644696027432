import * as React from "react";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { setNonVegItem } from "../../utils/ReduxStore/nonVegslice";
import { setVegItem } from "../../utils/ReduxStore/VegSlice";

const Nonvegbtn = () => {
  const [isChecked, setIsChecked] = React.useState(false);
  const data = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const vegItems = useSelector((state) => state.veg.vegItems);
  const nonvegItems = useSelector((state) => state.nonveg.nonvegItems);

  React.useEffect(() => {
    if (vegItems) {
      dispatch(setNonVegItem(false));
      setIsChecked(!vegItems);
    }
  }, [vegItems, dispatch]);

  const handleChange = () => {
    setIsChecked((prev) => !prev);

    if (!isChecked) {
      dispatch(setVegItem(false));
      dispatch(setNonVegItem(true));
    } else {
      dispatch(setNonVegItem(false));
    }
  };
  React.useEffect(() => {
    if (nonvegItems) {
      setIsChecked(true);
    }
  }, [nonvegItems]);

  return (
    <div className="flex justify-center items-center m-1 border rounded-2xl w-20 h-9">
      <Switch
        color="default"
        onChange={handleChange}
        checked={isChecked}
        sx={{
          "& .MuiSwitch-thumb": {
            width: "21px",
            height: "21px",
            borderRadius: "4px",
            boxShadow: "none",
            backgroundImage: isChecked
              ? "url(Images/NonVeg.png)"
              : "url(Images/NonVeg.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          },
          "& .MuiSwitch-track": {
            backgroundColor: isChecked ? "red" : "gray",
            width: "40px",
            height: "12px",
          },

          "&:focus": {
            "--tw-ring-color": "red",
          },
        }}
      />
      {/* <label htmlFor="VEG">
        <img
          src="https://yenu.menu/demo/images/non-vegetarian.svg"
          alt="Non Veg Only"
          className="w-5 inline-block mx-1"
        />
      </label> */}
    </div>
  );
};

export default Nonvegbtn;
