import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    selectedItem: null,
  },
  reducers: {
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    deleteSelectedItem: (state) => {
      state.selectedItem = null;
    },
  },
});

export const { setSelectedItem, deleteSelectedItem } = searchSlice.actions;

export default searchSlice.reducer;
