import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cartSlice";
import searchReducer from "./SearchSlice";
import dataReducer from "./dataSlice";
import vegReducer from "./VegSlice";
import orderReducer from "./orderSlice";
import nonVegReducer from "./nonVegslice";
import categoryReducer from "./categorySlice";
import filterSearchItemsReducer from "./filterSearchItems";

const appStore = configureStore({
  reducer: {
    cart: cartReducer,
    search: searchReducer,
    data: dataReducer,
    veg: vegReducer,
    nonveg: nonVegReducer,
    order: orderReducer,
    category: categoryReducer,
    searchItems: filterSearchItemsReducer,
  },
});
export default appStore;
