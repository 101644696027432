import { createSlice } from "@reduxjs/toolkit";

const categoryDataSlice = createSlice({
  name: "category",
  initialState: {}, // Initial state set to an empty object
  reducers: {
    setCategoryData: (state, action) => {
      const { categoryId, data } = action.payload;

      if (state[categoryId]) {
        state[categoryId] = data;
      } else {
        state[categoryId] = data;
      }
    },
  },
});

export const { setCategoryData } = categoryDataSlice.actions;
export default categoryDataSlice.reducer;
