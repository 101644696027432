import React, { useState, useEffect } from "react";
import Category from "./Catagory";
import CategoryItem from "./CategoryItem";
import axios from "axios";
import configData from "../../utils/config.json";
import { useNavigate } from "react-router-dom";
import DATA from "../../data/Images/data.json";
import { setData } from "../../utils/ReduxStore/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import Filterbtn from "../header/Filterbtn";
import Nonvegbtn from "../header/Non-vegbtn";
import SearchItems from "./SearchItems";
import OTPScreen from "./OTPScreen"; // Import the OTP component

const Home = () => {
  const [otpPopup, setOtpPopup] = useState(false); // OTP popup state
  const [otp, setOtp] = useState(""); // OTP value state

  const navigate = useNavigate();
  const id = localStorage.getItem("org_unique_id");

  const [categoryIndex, setCategoryIndex] = useState();
  const dispatch = useDispatch();
  dispatch(setData(DATA));

  const handleOnClick = (index) => {
    setCategoryIndex(index);
  };

  // const reorderData = (data) => {
  //   const order = ["Soup", "Starters", "Main Course", "Beverages", "Dessert"];
  //   const reorderedData = [];

  //   order.forEach((categoryName) => {
  //     const category = data.find((item) => item.category_name === categoryName);
  //     if (category) {
  //       reorderedData.push(category);
  //     }
  //   });

  //   return reorderedData;
  // };

  const data = useSelector((state) => state.data);

  const handleOtpSubmit = async (otp) => {
    try {
      const response = await axios.post(`${configData.SERVER_URL}verify-otp`, {
        otp,
      });
      if (response.data.success) {
        setOtpPopup(false);
        // Handle successful OTP verification (e.g., navigate or display success message)
      } else {
        // Handle OTP verification failure (e.g., show error message)
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };
  console.log(data.itemsCategory);

  return (
    <div className="w-full pb-40 relative">
      {otpPopup && (
        <OTPScreen
          handleOtpSubmit={handleOtpSubmit}
          closePopup={() => setOtpPopup(false)}
        />
      )}
      <Category data={data.itemsCategory} onclick={handleOnClick}></Category>
      <div className="flex justify-between items-center m-2 p-2">
        <h2 className="ml-0 lg:ml-2 text-lg font-bold">Menu</h2>
        <div className="flex">
          <Filterbtn />
          <Nonvegbtn />
        </div>
      </div>
      <SearchItems></SearchItems>
      <CategoryItem
        data={data.itemsCategory}
        org_unique_id={id}
        categoryIndex={categoryIndex}
        setCategoryIndex={setCategoryIndex}
      ></CategoryItem>
    </div>
  );
};

export default Home;
