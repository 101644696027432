import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom"; // Import Routes
import "./App.css";

import Header from "./components/header/Header";
import Home from "./components/home/Home";
import Footer from "./components/Footer/Footer";

import About from "./components/Aboutus/About";
import Payus from "./components/Pay_us/Payus";
import Cart from "./components/cart/Cart";
import { Provider } from "react-redux";
import appStore from "./utils/ReduxStore/appStore";
import Order from "./components/OrderPlacedItems/Order";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = localStorage.getItem("org_unique_id");
  useEffect(() => {
    if (id) {
      //navigate("/home");
      if (location.pathname === "/") {
        navigate("/home");
      }
    } else {
      navigate("/home");
    }
  }, [navigate]);

  return (
    <Provider store={appStore}>
      <div
        style={{
          maxWidth: "600px",
          margin: "auto",
          padding: "15px 10px",
        }}
        className="flex justify-center items-center pb-40 flex-col"
      >
        <Header />
        <Outlet />
        <Footer />
      </div>
    </Provider>
  );
}

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/payus",
        element: <Payus />,
      },
      {
        path: "/order",
        element: <Order></Order>,
      },
      {
        path: "/cart",
        element: <Cart />,
      },
    ],
  },
]);
export const Main = () => {
  return <RouterProvider router={appRouter} />;
};
export default Main;
