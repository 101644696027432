import React from "react";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const Shimmer = () => {
  return (
    <div>
      <Box>
        <Skeleton animation="wave" sx={{ height: "60px" }} />
        <Skeleton animation="wave" sx={{ height: "60px" }} />
        <Skeleton animation="wave" sx={{ height: "60px" }} />
        <Skeleton animation="wave" sx={{ height: "60px" }} />
        <Skeleton animation="wave" sx={{ height: "60px" }} />
      </Box>
    </div>
  );
};

export default Shimmer;
