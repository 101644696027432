import React from "react";
import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

const Category = ({ data, onclick }) => {
  setTimeout(() => {
    var slider = new KeenSlider("#my-slider", {
      mode: "snap",
      rtl: false,
      slides: {
        perView: "auto",
        spacing: 20,
      },
    });
    slider.update({
      mode: "snap",
      rtl: false,
      slides: {
        perView: "auto",
        spacing: 20,
      },
    });
  }, 0);
  return (
    <div
      style={{
        marginBottom: "25px",
        marginTop: "25px",
      }}
      className="w-full overflow-x-auto overflow-y-hidden"
    >
      <div
        id="my-slider"
        style={{
          margin: 0,
          padding: 0,
          listStyleType: "none",
        }}
        className="keen-slider"
        // className="flex flex-nowrap py-2 mt-2 space-x-2 overflow-x-auto scrollbar-hide"
      >
        {data.map((category, index) => (
          <button
            className="keen-slider__slide"
            key={index}
            // className="px-4 py-2 flex justify-center items-center  rounded-md min-w-fit focus:outline-none focus:bg-gray-300"
            style={{
              overflow: "visible",
              textAlign: "center",
              backgroundColor: "#eee",
              borderRadius: "5px",
              transform: "translate3d(0px, 0px, 0px)",
            }}
            onClick={() => onclick(index)}
          >
            <span
              style={{
                padding: "12px 21px",
                fontSize: "15px",
                color: "#000",
                display: "inline-block",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
              // className="flex items-center"
            >
              {category.categoryName}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Category;
