import react from "react";
import { createSlice } from "@reduxjs/toolkit";
const filterSearchItemsSlice = createSlice({
  name: "searchItems",
  initialState: {
    searchItems: [],
    OpenIndex: 0,
    showParagraph: false,
  },
  reducers: {
    setSearchItem: (state, action) => {
      state.searchItems = action.payload;
    },
    setOpenIndex: (state, action) => {
      state.OpenIndex = action.payload;
    },
    setshowParagraph: (state, action) => {
      state.showParagraph = action.payload;
    },
  },
});
export const { setSearchItem, setshowParagraph, setOpenIndex } =
  filterSearchItemsSlice.actions;

export default filterSearchItemsSlice.reducer;
