import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCartItemsDetails,
  deleteItem,
  setCartItems,
  clearCartId,
  setOrderTypeChecked,
} from "../../utils/ReduxStore/cartSlice";
import { RiDeleteBin6Line } from "react-icons/ri";
import { placedOrder } from "../../utils/ReduxStore/orderSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setalert } from "../../utils/ReduxStore/orderSlice";
import Switch from "@mui/material/Switch";

const Cart = () => {
  const cartItems = useSelector((store) => store.cart.items);
  const [isChecked, setIsChecked] = React.useState(false);
  // const [selectedOption, setSelectedOption] = useState("Dine in");
  const [orderType, setOrdertype] = React.useState("Dine in");
  // const options = ["Dine-in", "Pickup"];

  const [isOpen, setIsOpen] = useState(false);

  // const handleOptionClick = (option) => {
  //   setSelectedOption(option);
  //   setOrdertype(option);
  //   setIsOpen(false);
  // };

  const handleChange = (item, index) => {
   dispatch(setOrderTypeChecked(index));

  };

  const dispatch = useDispatch();
  const [subtotal, setSubTotal] = useState(0);

  const showAlert = useSelector((state) => state.order.ShowAlert);

  const handlAdditem = useCallback(
    (item) => {
      return () => {
        dispatch(
          updateCartItemsDetails({
            quantity: item.quantity,
            item: item,
            action: "ADD",
          })
        );
      };
    },
    [dispatch]
  );

  const handlDltitem = (item) => {
    if (cartItems.length === 1) {
      dispatch(clearCartId());
      dispatch(setCartItems([]));
    } else {
      dispatch(deleteItem(item));
    }
  };

  const handlRemoveitem = useCallback(
    (item) => {
      return () => {
        dispatch(
          updateCartItemsDetails({
            quantity: item.quantity,
            item: item,
            action: "REMOVE",
          })
        );
      };
    },
    [dispatch]
  );

  useEffect(() => {
    let total = 0;
    cartItems.forEach((element) => {
      const quantity = element.quantity ? element.quantity : 1;
      total += element.itemPrice * quantity;
    });

    const formattedNumber = total.toFixed(2);
    setSubTotal(formattedNumber);
  }, [cartItems]);

  const handlePlaceOrder = useCallback(
    (items) => {
      return () => dispatch(placedOrder(items));
    },
    [dispatch]
  );

  useEffect(() => {
    // console.log("🚀 ~ useEffect ~ showAlert:", showAlert);
    if (showAlert !== "") {
      if (showAlert === "please Add some item into Cart") {
        toast.warning(showAlert, { position: "top-center" });
      } else if (showAlert === "order Failed") {
        toast.error(showAlert, { position: "top-center" });
      } else {
        toast.success(showAlert, { position: "top-center" });
      }
    }
    dispatch(setalert(""));
  }, [showAlert, dispatch]);
  return (
    <div className=" w-full pb-40">
      <div className="border-b ">
        {cartItems.length > 0 ? (
          cartItems.map((item, index) => {
            return (
              <div
                key={`${item.dish_id}_${index}`}
                className="flex bg-gray-50 my-4 w-full p-2  "
              >
                <div className="w-4/12">
                  <img
                    src={`https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/Dine-O-Code/Data/Images${item.itemImage}`}
                    alt=""
                    className="w-full rounded"
                  />
                  <div
                    style={{
                      backgroundColor: "#fff",
                      width: "75%",
                      display: "flex",
                      justifyContent: "space-around",
                      textAlign: "center",
                      padding: "7px 0",
                      margin: "-24px auto 0 auto",
                      position: "relative",
                      borderRadius: "5px",
                      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <button
                      style={{
                        fontWeight: "bold",
                        color: "#49A24C",
                        cursor: "pointer",
                      }}
                      onClick={handlRemoveitem(item)}
                    >
                      -
                    </button>
                    <h1>{item.quantity}</h1>
                    <button
                      style={{
                        fontWeight: "bold",
                        color: "#49A24C",
                        cursor: "pointer",
                      }}
                      onClick={handlAdditem(item)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="w-8/12 flex justify-between items-center">
                  <div className="w-7/12 mx-6 ">
                    {item.dish_type === "Vegetarian" && (
                      <img src="Images/Veg.png" alt="" className="w-5 " />
                    )}
                    {item.dish_type === "Non Vegetarian" && (
                      <img
                        className="w-5"
                        src="Images/NonVeg.png"
                        alt="Non-Vegetarian"
                      />
                    )}
                    {item.dish_type === "Vegan" && (
                      <img
                        src="https://yenu.menu/demo/images/vegan.svg"
                        alt=""
                        className="w-5"
                      />
                    )}
                    <h1 className="font-extrabold text-m my-2">
                      {item.itemName}
                    </h1>
                    <h1 className="font-bold">
                      {item.currency_type}₹{item.itemPrice}
                    </h1>
                  </div>

                  {/* <div className="relative inline-block text-left">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      {selectedOption}
                    </button>
                    {isOpen && (
                      <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <div
                          className="py-1"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="options-menu"
                        >
                          {options.map((option, index) => (
                            <button
                              key={index}
                              onClick={() => handleOptionClick(option)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                              role="menuitem"
                            >
                              {option}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div> */}
                  <div className="w-5/12 flex flex-col items-end ">
                    <div className="cursor-pointer">
                      <RiDeleteBin6Line
                        size={20}
                        onClick={() => handlDltitem(item)}
                      />
                    </div>

                    <div className="w-7/12 lg:w-4/12 md:w-5/12">
                      <Switch
                        color="default"
                        onChange={() => {
                          handleChange(item, index);
                        }}
                        checked={item.isChecked}
                        sx={{
                          "& .MuiSwitch-thumb": {
                            width: "21px",
                            height: "21px",
                            borderRadius: 100,
                            boxShadow: "none",
                            padding: 0,
                            backgroundColor: isChecked ? "#49A24C" : "#49A24C",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            // background: "transparent",
                          },
                          "& .MuiSwitch-track": {
                            backgroundColor: isChecked ? "#49A24C" : "gray",
                            width: "40px",
                            height: "12px",
                          },
                        }}
                      />
                    </div>
                    <p className="text-sm">{item.isChecked===true?"Take away":"Dine in"}</p>

                    {/* <div className="relative inline-block text-left m-2">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        {selectedOption}
                      </button>
                      {isOpen && (
                        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                          <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            {options.map((option, index) => (
                              <button
                                key={index}
                                onClick={() => handleOptionClick(option)}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                role="menuitem"
                              >
                                {option}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className="m-4 font-bold">
            Your cart is empty! Please add dishes from Menu💚
          </p>
        )}
      </div>
      <div className="border-b">
        <div className=" flex justify-between">
          <h2 className="font-medium text-l py-2 px-4">Sub Total:</h2>
          <h2 className="font-normal text-l py-2 px-5 ">₹{subtotal}</h2>
        </div>
        <div className=" flex justify-between my-4">
          <h2 className="font-bold text-l  px-4 py-2">Total:</h2>
          <h2 className="font-bold text-l  px-4 py-2">₹{subtotal}</h2>
        </div>
      </div>
      <div className="flex items-center w-full justify-center">
        <div>
          <button
            className="w-full bg-green-600 p-4 my-8 mx-2 text-white rounded"
            onClick={handlePlaceOrder(cartItems)}
          >
            Place Order
          </button>
          <ToastContainer autoClose={1000} />
        </div>
        {/* {showBtn ? (
          <div>
            <button
              className="w-6/12 bg-green-500 p-4 my-8 mx-2 text-white rounded"
              onClick={handlePlaceOrder}
            >
              Place Order
            </button>
            <ToastContainer />
          </div>
        ) : (
          <Link to="/order">
            <button className="w-12/12 bg-green-500 p-4 my-8 mx-2 text-white rounded">
              View Order Status
            </button>
          </Link>
        )} */}
      </div>
    </div>
  );
};

export default Cart;
