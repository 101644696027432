import React, { useState, useEffect, useRef } from "react";
import CategoryItemList from "./CategoryItemList";
import { AiFillCaretDown } from "react-icons/ai";
// import {
//   setSelectedItem,
//   deleteSelectedItem,
// } from "../../utils/ReduxStore/SearchSlice";
import { useSelector, useDispatch } from "react-redux";
// import configData from "../../utils/config.json";
// import { setCategoryData } from "../../utils/ReduxStore/categorySlice";
// import axios from "axios";
import Shimmer from "../ShimmerUI/Shimmer";
// import { setNonVegItem } from "../../utils/ReduxStore/nonVegslice";

const CategoryItem = ({
  data,
  categoryIndex,
  org_unique_id,
  setCategoryIndex,
}) => {
  const [openIndex, setOpenIndex] = useState(null);

  const [categories, setCategories] = useState(null);
  const dispatch = useDispatch();
  const vegItems = useSelector((state) => state.veg.vegItems);
  const nonvegItems = useSelector((state) => state.nonveg.nonvegItems);
  const [vegNonvegdata,setvegNonvegdata]=useState(data)
  // console.log(nonvegItems);

  // const SearchOpenIndex = useSelector((state) => state.searchItems.OpenIndex);
  // console.log("🚀 ~ SearchOpenIndex:", SearchOpenIndex);

  // const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   if (data.length > 0) toggleAccordion(0);
  // }, [data.length]);

  // // useEffect(() => {
  // //   if (selectedItem?.payload?.search?.selectedItem) {
  // //     const id = selectedItem?.payload?.search?.selectedItem.category_id;
  // //     const index = data.findIndex((item) => item.id === id);
  // //     setOpenIndex(index);
  // //     getCategoryData(id);
  // //   }
  // // }, [data, selectedItem?.payload?.search?.selectedItem]);

  const elementsRef = useRef([]);
  useEffect(() => {
    if (categoryIndex != null) {
      const adjustedIndex = categoryIndex;
      setTimeout(() => {
        elementsRef.current[adjustedIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, [0]);
      setOpenIndex(categoryIndex);
      // const idCategory = data[categoryIndex].id;
      // getCategoryData(idCategory);
    }
  }, [categoryIndex]);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
    // const categoryId = data[index]?.id;
    // getCategoryData(categoryId);
    setTimeout(() => {
      elementsRef.current[index]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      console.log("Smooth");
    }, [0]);
  };
  // useEffect(() => {
  //   if (openIndex !== null) {
  //     const element = elementsRef.current[openIndex - 1];
  //     if (element) {
  //       element.scrollIntoView({
  //         behavior: "smooth",
  //         block: "start",
  //       });
  //     }
  //   }
  // }, [openIndex, categories]);

  // const getCategoryData = async (id) => {
  //   if (id !== undefined) {
  //     setCategoryIndex(null);
  //     setCategories(null);
  //     setIsLoading(true);
  //     if (vegItems) {
  //       try {
  //         const response = await axios.get(
  //           `${configData.SERVER_URL}products/guest/${org_unique_id || ""}`,
  //           {
  //             params: {
  //               category_id: id,
  //               type: "Veg",
  //             },
  //           }
  //         );

  //         setCategories(response.data.products);
  //       } catch (error) {
  //         console.error("Error fetching categories:", error);
  //       }
  //     } else if (nonvegItems) {
  //       try {
  //         const response = await axios.get(
  //           `${configData.SERVER_URL}products/guest/${org_unique_id || ""}`,
  //           {
  //             params: {
  //               category_id: id,
  //               type: "Non-Veg",
  //             },
  //           }
  //         );

  //         setCategories(response.data.products);
  //         // }
  //       } catch (error) {
  //         console.error("Error fetching categories:", error);
  //       }
  //     } else {
  //       try {
  //         const response = await axios.get(
  //           `${configData.SERVER_URL}products/guest/${org_unique_id || ""}`,
  //           {
  //             params: {
  //               category_id: id,
  //             },
  //           }
  //         );
  //         const newData = response.data.products;
  //         dispatch(setCategoryData({ categoryId: id, data: newData }));
  //         setCategories(newData);
  //       } catch (error) {
  //         console.error("Error fetching categories:", error);
  //       }
  //     }
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    // debugger
    if (vegItems && Array.isArray(data)) {
      const filteredData = [...data];
      filteredData.forEach((obj, index) => {
        const filteredCategories = obj.categoryItems?.filter(
          (item) => item.itemType === "Vegetarian"
        );
        filteredData[index] = {
          ...obj,
          categoryItems: filteredCategories,
        };
      });
      setvegNonvegdata(filteredData);
      setOpenIndex(0)
      console.log(filteredData, "filteredData");
    } else if (nonvegItems && Array.isArray(data)) {
      const filteredData = [...data];
      filteredData.forEach((obj, index) => {
        const filteredCategories = obj.categoryItems?.filter(
          (item) => item.itemType === "Non Vegetarian"
        );
        filteredData[index] = {
          ...obj,
          categoryItems: filteredCategories,
        };
      });
       setvegNonvegdata(filteredData);
        setOpenIndex(0);
      console.log(filteredData, "filteredData");
    }else{
       setvegNonvegdata(data);
    }
  }, [vegItems,nonvegItems, data]);

  return (
    <div>
      {vegNonvegdata.length !== 0 ? (
        vegNonvegdata.map((items, index) => {
          return (
            <div key={index}>
              <div
                ref={(ref) => (elementsRef.current[index] = ref)}
                className=" lg:w-full my-4 lg:p-2 flex justify-between rounded sm:w-full sm:p-4 "
                onClick={() => {
                  toggleAccordion(index);
                }}
              >
                <h2 className="font-bold text-lg ">{items.categoryName}</h2>
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    toggleAccordion(index);
                  }}
                >
                  <AiFillCaretDown size={25} />
                </p>
              </div>
              <CategoryItemList
                index={index}
                openIndex={openIndex}
                items={categories}
                // isLoading={isLoading}
                user_unique_id={org_unique_id}
                data={items.categoryItems}
              ></CategoryItemList>
            </div>
          );
        })
      ) : (
        <Shimmer></Shimmer>
      )}
    </div>
  );
};

export default CategoryItem;
