import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import configData from "../../utils/config.json";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import { clearCart, clearCartId } from "./cartSlice";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    items: [],
    order_id: "",
    ShowAlert: "",
  },
  reducers: {
    addOrder: (state, action) =>
      void (state.order_id = action.payload.order_id),
    getOrders: (state, action) => {
      state.items = action.payload
     
    },
    setalert: (state, action) => void (state.ShowAlert = action.payload),
    clearOrder: (state, action) => {
      state.items = [];
    },
    clearOrderId: (state, action) => {
      state.order_id = "";
    },
  },
});
export const placedOrder = createAsyncThunk(
  "orderItems/post",
  async (args, { getState, dispatch }) => {
    const table_no = localStorage.getItem("tabel_no");
    const guest_id = localStorage.getItem("guest_id");
    // const id = localStorage.getItem("org_unique_id");
    const items = args;
    // const order_id = uuidv4();
    const generateCustomOrderID = () => {
      const prefix = "DineO";
      const numericPartLength = 4; // Adjust the length of the numeric part as needed
      const numericPart = Math.floor(
        Math.random() * Math.pow(10, numericPartLength)
      )
        .toString()
        .padStart(numericPartLength, "0");
      return `${prefix}${numericPart}`;
    };

    // Usage
    const order_id = generateCustomOrderID();
    // console.log(order_id); // Example: "DineO3211"

    if (!items || items.length === 0) {
      dispatch(setalert("please Add some item into Cart"));
      return;
    }
    // try {
    //   const requestData = items.map((item) => ({
    //     order_id: order_id,
    //     guest_id: guest_id,
    //     table_number: table_no,
    //     dish_id: item.dish_id,
    //     quantity: item.quantity,
    //     price: item.price,
    //     user_id: item.user_unique_id,
    //     status: "order placed",
    //     order_type: "Dine-in",
    //   }));

    //   const response = await axios.post(
    //     `${configData.SERVER_URL}orders`,
    //     requestData
    //   );
      // localStorage.setItem("Order_Id", order_id);
      // dispatch(addOrder(order_id));
      
      dispatch(clearCart());
      dispatch(clearCartId());
      dispatch(setalert("Order Successfully!"));
      dispatch(getOrders(items));
  //   } catch (error) {
  //     console.log(error);
  //     dispatch(setalert("order Failed"));
  //   }
   }
);
export const getOrderdetails = createAsyncThunk(
  "orderItems/get",
  async (items, { dispatch }) => {
    // try {
    //   const response = await axios.get(`${configData.SERVER_URL}orders/guest`, {
    //     params: { guest_id: guest_id },
    //   });
     
  //   } catch (error) {
  //     console.log(error);
  //   }
   }
);
export const { addOrder, getOrders, setalert, clearOrder, clearOrderId } =
  orderSlice.actions;
export default orderSlice.reducer;
