import React, { useState, useEffect } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedItem } from "../../utils/ReduxStore/SearchSlice";
import {
  setSearchItem,
  setshowParagraph,
  setOpenIndex,
} from "../../utils/ReduxStore/filterSearchItems";
import axios from "axios";
import configData from "../../utils/config.json";
import DATA from "../../data/Images/data.json";

export default function SearchBar() {
  const [inputValue, setInputValue] = useState("");
  // const [filteredOptions, setFilteredOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();
  const vegItems = useSelector((state) => state.veg.vegItems);
  const org_unique_id = localStorage.getItem("org_unique_id");
  const nonvegItems = useSelector((state) => state.nonveg.nonvegItems);

  const fetchData = async () => {
    // try {
    //   const response = await axios.get(
    //     `${configData.SERVER_URL}products/guest/${org_unique_id}`,
    //     {}
    //   );
    const response = DATA.itemsCategory.flatMap((obj) =>
      obj.categoryItems?.filter((item) => item.itemMeta === "")
    );
    console.log(response, "response Search");
    if (vegItems) {
      const vegFilter = response.filter((item) => {
        if (item.itemType === "Vegetarian") {
          return true;
        } else {
          return false;
        }
      });
      setItems(vegFilter);
    } else {
      setItems(response);
    }
  };

  useEffect(() => {
    if (inputValue) {
      const filtered = items.filter(
        (option) =>
          option.itemName.toLowerCase().includes(inputValue.toLowerCase()) ||
          option.itemName
            .toLowerCase()
            .split(" ")
            .some((substring) => substring.includes(inputValue.toLowerCase()))
      );

      if (vegItems) {
        dispatch(setSearchItem([]));
        const vegItemsArray = filtered.filter((item) => {
          if (item.itemType === "Vegetarian") {
            return true;
          } else {
            return false;
          }
        });
        if (vegItemsArray.length === 0) {
          dispatch(setshowParagraph(true));
        } else {
          dispatch(setshowParagraph(false));
          dispatch(setOpenIndex(null));
          dispatch(setSearchItem(vegItemsArray));
        }
      } else if (nonvegItems) {
        dispatch(setSearchItem([]));
        const nonVegItemsArray = filtered.filter((item) => {
          if (item.itemType === "Non Vegetarian") {
            return true;
          } else {
            return false;
          }
        });
        if (nonVegItemsArray.length === 0) {
          dispatch(setshowParagraph(true));
        } else {
          dispatch(setshowParagraph(false));
          dispatch(setOpenIndex(null));
          dispatch(setSearchItem(nonVegItemsArray));
        }
      } else {
        if (filtered.length === 0) {
          dispatch(setshowParagraph(true));
        } else {
          dispatch(setshowParagraph(false));
          dispatch(setOpenIndex(null));
          dispatch(setSearchItem(filtered));
        }
      }

      setShowDropdown(true);
    } else {
      setShowDropdown(false);
      dispatch(setshowParagraph(false));
      dispatch(setOpenIndex(0));
      dispatch(setSearchItem([]));
    }
  }, [inputValue, items, nonvegItems, vegItems]);
  // const handleKeyDown = (event) => {
  //   if (event.keyCode === 13) {
  //     handleItemClick({
  //       name: inputValue,
  //       category_id: filteredOptions[0].category_id,
  //     });
  //   }
  // };

  // const handleItemClick = (item) => {
  //   debugger;
  //   dispatch(setSelectedItem(item));
  //   setShowDropdown(false);
  //   setInputValue("");
  // };

  return (
    <div className="relative w-full">
      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none  lg:w-full">
        <SearchIcon className="ml-2 h-5 w-5 text-gray-400" />
      </div>
      <input
        type="text"
        className="block  w-full py-2 border pl-7 mb-2 border-solid border-0.5 border-gray-200 mt-2 rounded-md focus:ring-green-600 focus:border-green-400 sm:text-sm"
        placeholder="Search..."
        value={inputValue}
        onClick={fetchData}
        // onKeyDown={handleKeyDown}
        onChange={(e) => setInputValue(e.target.value)}
      />
      {showDropdown && (
        <div className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-lg text-black">
          {/* {filteredOptions.map((option) => (
            <div
              key={option.id}
              className="px-2 py-1 cursor-pointer hover:bg-gray-100"
              onClick={() => handleItemClick(option)}
            >
              {option.name}
            </div>
          ))} */}
        </div>
      )}
    </div>
  );
}
