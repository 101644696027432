import React, { useState, useEffect, useRef } from "react";
import SearchBar from "./SearchBar";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoIosNotifications } from "react-icons/io";
import { RiLogoutBoxRLine } from "react-icons/ri";
import {
  getOrderdetails,
  clearOrder,
  clearOrderId,
} from "../../utils/ReduxStore/orderSlice";

const Header = () => {
  const location = useLocation();
  const data = useSelector((state) => state.data);

  const isHomePage = location.pathname === "/home";
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const orders = useSelector((state) => state.order);

  // useEffect(() => {

  // }, []);

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [LogOutdropdownOpen, setLogOutDropdownOpen] = useState(false);
  const logOutClicked = () => {
    window.location.href = "https://www.tigermenu.com/home.html";
  };

  const dropdownRef = useRef(null);
  const dropdownRefLogout = useRef(null);

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     // setDropdownOpen(false);
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       dropdownRefLogout.current &&
  //       !dropdownRefLogout.current.contains(event.target)
  //     ) {
  //       setLogOutDropdownOpen(false);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };
  // const toggleDropdownLogout = () => {
  //   setLogOutDropdownOpen(!dropdownOpen);
  // };

  return (
    <div className="flex items-center w-full border-b border-gray-200">
      <div className="flex gap-2 justify-between w-full mt-2 ">
        <div className="flex justify-center items-center w-5/12">
          <Link to="/home">
            <img
              // className="w-24 mb-2 ml-1"
              // className="w-9/12  mb-1 ml-1 h-full"
              className="w-full"
              src={"Images/Logo.png"}
              alt="logo"
            />
          </Link>
        </div>
        <div className="w-7/12 flex flex-col justify-start items-end">
          <div className=" flex justify-end items-center">
            <div className="flex justify-end mt-0 mx-2 " ref={dropdownRef}>
              {/* <IoIosNotifications
                size={27}
                style={{ color: "black", marginBottom: "0" }}
                onClick={toggleDropdown}
              /> */}
              <Link to="/order">
                <img src="Images/orders-icon.png" alt="" className="w-6 mr-2" />
                {orders.items.length > 0 && (
                  <div className="bg-green-500 w-4 h-4 absolute top-10 text-sm flex justify-center items-center  rounded-full">
                    {orders.items && orders.items.length}
                  </div>
                )}
              </Link>

              {/* {dropdownOpen && (
                <div className="absolute bg-white shadow-md rounded-md mt-4 z-10">
                  <Link to="/order">
                    <button
                      onClick={() => {
                        setDropdownOpen(false);
                      }}
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
                    >
                      Order Status
                    </button>
                  </Link>
                </div>
              )} */}
            </div>
            <div className="flex justify-end mt-0  " ref={dropdownRefLogout}>
              {/* <RiLogoutBoxRLine
                size={27}
                style={{ color: "#49A24C", marginBottom: "0" }}
                onClick={logOutClicked}
              /> */}
              <button
                onClick={logOutClicked}
                className="border border-black-50 bg-gray-50 rounded p-1 text-green-600"
              >
                Home
              </button>

              {/* {LogOutdropdownOpen && (
                <div className="absolute bg-white shadow-md rounded-md mt-5 z-10">
                  <button
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
                    onClick={logOutClicked}
                  >
                    Logout
                  </button>
                </div>
              )} */}
            </div>
          </div>
          {isHomePage && <SearchBar data={data} />}
        </div>
      </div>
    </div>
  );
};

export default Header;
