import { createSlice } from "@reduxjs/toolkit";

const VegSlice = createSlice({
  name: "veg",
  initialState: {
    vegItems: false,
  },
  reducers: {
    setVegItem: (state, action) => {
      state.vegItems = action.payload;
    },
  },
});

export const { setVegItem } = VegSlice.actions;

export default VegSlice.reducer;
