import React from "react";
import DATA from "../../utils/restaurentdata.json";

const Payus = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full pb-40">
      <div className="flex items-center justify-center w-full  mx-4 md:mx-80 mt-2">
        <div className="p-2 m-2 flex flex-col items-center">
          <img
            src={"https://yenu.menu/demo/images/qr.jpg"}
            alt="QR Code"
            className="w-full"
          />
          <h3 className="font-bold m-2 p-4">Scan the QR code to pay</h3>
        </div>
      </div>

      <div className="mt-15px flex flex-wrap justify-center">
        {DATA.payment.type.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-col justify-center items-center"
              style={{
                width: " calc(50% - 10px)",
                backgroundColor: " #f7f7f7",
                textAlign: " center",
                padding: " 40px 10px",
                borderRadius: " 5px",
                marginTop: " 15px",
                margin: "4px",
                boxSizing: "border-box",
              }}
            >
              <img src={item.img} alt={item.name} className="w-40" />
              <h2 className="font-extrabold">{item.name}</h2>
              <h3>{item.link}</h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Payus;
