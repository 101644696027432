import { createSlice } from "@reduxjs/toolkit";

const nonVegSlice = createSlice({
  name: "nonveg",
  initialState: {
    nonvegItems: false,
  },
  reducers: {
    setNonVegItem: (state, action) => {
      state.nonvegItems = action.payload;
    },
  },
});

export const { setNonVegItem } = nonVegSlice.actions;

export default nonVegSlice.reducer;
