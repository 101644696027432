import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "boxicons/css/boxicons.min.css";
// import { FaInfoCircle } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa";
import { MdShoppingCart } from "react-icons/md";
import "./footer.css";
import { getCartItemsDetails } from "../../utils/ReduxStore/cartSlice";

const Footer = () => {
  const location = useLocation();
  const cartItems = useSelector((store) => store.cart.items);
  const [subtotal, setSubTotal] = useState(0);
  const [cartChanged, setCartChanged] = useState(false);
  const cart_Id = localStorage.getItem("cart_Id");
  const dispatch = useDispatch();
  // const org_unique_id = localStorage.getItem("org_unique_id");

  useEffect(() => {
    let total = 0;
    console.log("Cart",cartItems)
    cartItems.forEach((element) => {
      console.log(element,"element")
      const quantity = element.quantity ? element.quantity : 1;
      total += element.itemPrice * quantity;
    });
    // const formattedNumber = total.toFixed(2);
    setSubTotal(total);
  }, [cartItems]);
  useEffect(() => {
    setCartChanged(true);
    setTimeout(() => {
      setCartChanged(false);
    }, 700);
  }, [subtotal]);
  // useEffect(() => {
  //   dispatch(getCartItemsDetails(cart_Id));
  //   console.log(cartItems);
  // }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
        boxShadow: "0px -5px 10px -5px rgba(0, 0, 0, 0.1)",
        position: "fixed",
        bottom: "0",
        backgroundColor: "#fff",
        zIndex: "999",
        marginLeft: "-10px",
        marginRight: "-10px",
      }}
      className="w-full lg:w-5/12"
    >
      <Link
        to="/home"
        className="flex flex-col items-center justify-center"
        style={{
          borderTop:
            location.pathname === "/home" ? "3px solid #49A24C" : "none",
          padding: "10px 0 5px 0",
          boxSizing: "border-box",
          width: "25%",
        }}
      >
        {/* <img
          className="text-center h-6"
          src="https://yenu.menu/demo/images/menu.svg"
          alt="Menu"
        /> */}
        <i className="bx bx-food-menu" style={{ fontSize: "1.5em" }}></i>

        <div className="flex w-full my-2 left-2 justify-center">
          <p className="">Menu</p>
        </div>
      </Link>
      <Link
        to="/about"
        className="flex flex-col items-center justify-center"
        style={{
          borderTop:
            location.pathname === "/about" ? "3px solid #49A24C" : "none",
          padding: "10px 0 5px 0",
          boxSizing: "border-box",
          width: "25%",
        }}
      >
        {/* <img
          className="text-center h-6"
          src="https://yenu.menu/demo/images/about.svg"
          alt="About us"
        /> */}
        {/* <FaInfoCircle /> */}
        <i className="bx bx-info-circle" style={{ fontSize: "1.5em" }}></i>
        <div className="flex w-full my-2 left-2 justify-center">
          <p className="">About us</p>
        </div>
      </Link>
      <Link
        to="/payus"
        className="flex flex-col items-center justify-center"
        style={{
          borderTop:
            location.pathname === "/payus" ? "3px solid #49A24C" : "none",
          padding: "10px 0 5px 0",
          boxSizing: "border-box",
          width: "25%",
        }}
      >
        {/* <img
          className="text-center h-6"
          src="https://yenu.menu/demo/images/pay.svg"
          alt="Payments"
        /> */}
        <i className="bx bx-currency" style={{ fontSize: "1.5em" }}></i>
        <FaDollarSign
          style={{ fontSize: "1.5em", fontWeight: "", color: "#000000" }}
        />
        <div className="flex w-full my-2 left-2 justify-center">
          <p className="">Payments</p>
        </div>
      </Link>
      {cartItems.length > 0 && (
        <Link
          to="/cart"
          className="flex flex-col items-center justify-center"
          style={{
            borderTop:
              location.pathname === "/cart" ? "3px solid #49A24C" : "none",
            padding: "10px 0 5px 0",
            boxSizing: "border-box",
            width: "25%",
          }}
        >
          <div className="text-center">
            <MdShoppingCart
              fontSize="1.5em"
              className={cartChanged ? "vibrate" : "active"}
            />
            {/* <BsCart color="#49A24C" fontSize="1.6em" /> */}
          </div>
          <div className="flex w-full my-2 left-2 justify-center ">
            <p className="text-green-600 font-bold text-align-center text-nowrap text-sm">
              ₹{subtotal}
            </p>
          </div>
        </Link>
      )}
      {cartItems.length === 0 && (
        <Link
          to="/cart"
          className="flex flex-col items-center justify-center"
          style={{
            borderTop:
              location.pathname === "/cart" ? "3px solid #49A24C" : "none",
            padding: "10px 0 5px 0",
            boxSizing: "border-box",
            width: "25%",
          }}
        >
          <MdShoppingCart fontSize="1.5em" />
          {/* <BsCart color="#666666" /> */}

          <div className="flex w-full my-2 left-2 justify-center">
            <p className="">Cart</p>
          </div>
        </Link>
      )}
    </div>
  );
};

export default Footer;
