import React, { useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addCartItems,
  updateCartItemsDetails,
  pushItems,
} from "../../utils/ReduxStore/cartSlice";
import ShimmerCategoryList from "./ShimmerCategoryList";

const CategoryItemList = ({ openIndex, index, isLoading, data }) => {
  const dispatch = useDispatch();
  const cartitem = useSelector((store) => store.cart.items);
  console.log(data, "dataaaaa");
  const isItemAdded = (itemId) => {
    const cartitemFilter = cartitem.filter((item) => {
      if (item.itemId === itemId) {
        return true;
      } else {
        return false;
      }
    });
    if (cartitemFilter.length === 0) {
      return true;
    } else {
      return false;
    }
  };
  const getQty = useCallback(
    (itemId) => {
      const filterCart = cartitem.filter((item) => {
        if (item.itemId === itemId) {
          return true;
        } else {
          return false;
        }
      });
      console.log("qty1111", filterCart);
      return filterCart[0].quantity;
    },
    [cartitem]
  );
  const addQuantity = useCallback(
    (item) => {
      return () =>
        dispatch(
          updateCartItemsDetails({
            quantity: getQty(item.itemId),
            item: item,
            action: "ADD",
          })
        );
    },
    [dispatch, getQty]
  );
  const decreaseQuantity = useCallback(
    (item) => {
      return () =>
        dispatch(
          updateCartItemsDetails({
            quantity: getQty(item.itemId),
            item: item,
            action: "REMOVE",
          })
        );
    },
    [dispatch, getQty]
  );
  const scrollRef = useRef(null);

  const handleAddClick = useCallback(
    (item) => {
      return () => dispatch(pushItems(item));
    },
    [dispatch]
  );
  // console.log(data);

  return (
    <div ref={scrollRef}>
      {openIndex === index && (
        <div>
          {isLoading ? (
            <div>
              <ShimmerCategoryList></ShimmerCategoryList>
            </div>
          ) : (
            <>
              {data && data.length > 0 ? (
                data.map((item, index) => {
                  return (
                    <div
                      key={`${item.dish_id}_${index}`}
                      style={{
                        display: " flex",
                        justifyContent: " space-between",
                        alignItems: " center",
                        backgroundColor: "#f7f7f7",
                        marginBottom: " 18px",
                        borderRadius: " 5px",
                        padding: " 10px",
                      }}
                      // className="flex lg:w-11/12 mx-4 rounded w-12/12"
                    >
                      <div
                        style={{
                          paddingRight: "20px",
                          width: "60%",
                        }}
                        // className="w-7/12 pr-2 lg:w-7/12 lg:pr-4"
                      >
                        {item.itemType === "Vegetarian" && (
                          <img
                            style={{
                              width: "22px",
                            }}
                            src="Images/Veg.png"
                            alt=""
                            className="w-5 "
                          />
                        )}
                        {item.itemType === "Non Vegetarian" && (
                          <img
                            style={{
                              width: "22px",
                            }}
                            className="w-5"
                            src="Images/NonVeg.png"
                            alt="Non-Vegetarian"
                          />
                        )}
                        {item.itemType === "Vegan" && (
                          <img
                            style={{
                              width: "22px",
                            }}
                            src="https://yenu.menu/demo/images/vegan.svg"
                            alt=""
                            className="w-5"
                          />
                        )}

                        <h1
                          style={{
                            fontWeight: "bold",
                            marginTop: "10px",
                            marginBottom: "10px",
                            padding: "0",
                          }}
                          // className="font-extrabold text-lg my-2"
                        >
                          {item.itemName}
                        </h1>
                        <p
                          style={{
                            fontSize: "0.94rem",
                            lineHeight: "1.35",
                            marginTop: "10px",
                            marginBottom: "10px",
                            padding: "0",
                            fontWeight: 400,
                          }}
                          // className="font-medium text-base leading-5 font-open-sans text-black  px-0 "
                        >
                          {item.itemDescription}
                        </p>

                        <h1
                          style={{
                            fontWeight: "bold",
                            marginBottom: "0",
                            marginTop: "10px",
                            padding: "0",
                          }}
                          // className="font-bold"
                        >
                          {/* {item.currency_type} */}₹{item.itemPrice}
                        </h1>
                      </div>
                      <div
                        style={{
                          width: "40%",
                        }}
                      >
                        <img
                          src={`https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/Dine-O-Code/Data/Images${item.itemImage}`}
                          alt=""
                          className="w-full rounded"
                        />
                        {isItemAdded(item.itemId) ? (
                          <div
                            style={{
                              backgroundColor: "#fff",
                              width: "75%",
                              textAlign: "center",
                              padding: "7px 0",
                              margin: "-24px auto 0 auto",
                              position: "relative",
                              borderRadius: "5px",
                              boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
                            }}
                            onClick={handleAddClick(item)}
                            // className="bottom-4 md:mt-0 md:relative bg-white rounded  lg:w-9/12 md:w-32 w-10/12 relative "
                          >
                            <button
                              style={{
                                fontWeight: "bold",
                                color: "#49A24C",
                                cursor: "pointer",
                              }}
                              // className="font-bold w-full text-red-700"
                            >
                              Add
                            </button>
                          </div>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "#fff",
                              width: "75%",
                              display: "flex",
                              justifyContent: "space-around",
                              textAlign: "center",
                              padding: "7px 0",
                              margin: "-24px auto 0 auto",
                              position: "relative",
                              borderRadius: "5px",
                              boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
                            }}
                            // className="flex bottom-4 lg:bottom-4 md:mt-0 md:relative bg-white rounded  lg:w-9/12 md:w-32 w-10/12 relative "
                          >
                            <button
                              style={{
                                fontWeight: "bold",
                                color: "#49A24C",
                                cursor: "pointer",
                              }}
                              // className="font-bold w-full text-red-700"
                              onClick={decreaseQuantity(item)}
                            >
                              -
                            </button>
                            <h1>{getQty(item.itemId)}</h1>
                            <button
                              style={{
                                fontWeight: "bold",
                                color: "#49A24C",
                                cursor: "pointer",
                              }}
                              // className="font-bold w-full text-red-700"
                              onClick={addQuantity(item)}
                            >
                              +
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="font-bold text-sm text-center text-gray-400">
                  Oops🙁 !! there is no items Found
                </p>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CategoryItemList;
