import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoIosClose } from "react-icons/io";
import { getOrderdetails } from "../../utils/ReduxStore/orderSlice";

const Order = () => {
  const orders = useSelector((state) => state.order);
  const dispatch = useDispatch();
  // const order_Id = localStorage.getItem("Order_Id");
  const tabel_no = localStorage.getItem("tabel_no");
  const guest_id = localStorage.getItem("guest_id");
  console.log(orders);
  useEffect(() => {
    if (guest_id) {
      dispatch(getOrderdetails(guest_id));
    }
  }, []);
  console.log(orders.items, "orders");
  return (
    <div className="pb-52 w-full">
      <h2 className="text-xl  font-bold mb-4 mt-2">Your Orders:</h2>
      <div className="border-b text-center ">
        {orders.items && orders.items.length > 0 ? (
          orders.items.map((item) => (
            <div key={item.itemId} className="flex bg-gray-50 my-4 p-2">
              <div className="w-4/12">
                <img
                  src={`https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/Dine-O-Code/Data/Images${item.itemImage}`}
                  alt=""
                  className="w-full rounded"
                />
              </div>

              <div className="w-7/12 mx-6 flex  flex-col justify-center items-start">
                <div className="font-bold flex w-full   justify-start items-center my-2">
                  <div className="flex w-10/12">
                    <div className="mt-1">
                      {item.itemType === "Vegetarian" && (
                        <img src="Images/Veg.png" alt="" className="w-5" />
                      )}
                      {item.itemType === "Non Vegetarian" && (
                        <img
                          src="Images/NonVeg.png"
                          alt="Non-Vegetarian"
                          className="w-5"
                        />
                      )}
                      {item.itemType === "Vegan" && (
                        <img
                          src="https://yenu.menu/demo/images/vegan.svg"
                          alt=""
                          className="w-5"
                        />
                      )}
                    </div>
                    <h1 className="text-left ml-2">{item.itemName}</h1>
                  </div>
                  <div className="mt-1">
                    <IoIosClose size={20} />
                  </div>
                  <h1>{item.quantity}</h1>
                </div>

                <div className="text-sm  text-gray-400 border px-2 py-1 m-1 ml-5 rounded-2xl">
                  <p>order placed</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="m-4 font-bold">Oops !! No orders found</p>
        )}
      </div>
      <div>
        {/* <h3 className="font-bold ">Order Id: {order_Id}</h3>
        <p className="font-bold ">Table no:{tabel_no}</p> */}
      </div>
    </div>
  );
};

export default Order;
