import React from "react";

const ShimmerCategoryList = () => {
  return (
    <div>
      <div
        style={{
          display: " flex",
          justifyContent: " space-between",
          alignItems: " center",
          backgroundColor: "#f7f7f7",
          marginBottom: " 18px",
          borderRadius: " 5px",
          padding: " 10px",
          height: "190px",
        }}
        // className="flex lg:w-11/12 mx-4 rounded w-12/12"
      >
        <div
          style={{
            paddingRight: "20px",
            width: "60%",
          }}
        >
          <h1
            style={{
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "10px",
              padding: "0",
              height: "20px",
              backgroundColor: "white",
            }}
            // className="font-extrabold text-lg my-2"
          ></h1>
          <p
            style={{
              fontSize: "0.94rem",
              lineHeight: "1.35",
              marginTop: "10px",
              marginBottom: "10px",
              padding: "0",
              fontWeight: 400,
              height: "20px",
              backgroundColor: "white",
            }}
            // className="font-medium text-base leading-5 font-open-sans text-black  px-0 "
          ></p>

          <h1
            style={{
              fontWeight: "bold",
              marginBottom: "0",
              marginTop: "10px",
              padding: "0",
              height: "20px",
              backgroundColor: "white",
            }}
          ></h1>
        </div>
        <div
          style={{
            width: "40%",
            backgroundColor: "white",
            height: "160px",
            border: "white",
            borderRadius: "2px",
          }}
        >
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerCategoryList;
